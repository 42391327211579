import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, TextField, Button, Dialog, DialogTitle, DialogContent, DialogActions, Select, MenuItem } from '@mui/material';

function Reviews({ password }) {
    const [reviews, setReviews] = useState([]);
    const [userNameFilter, setUserNameFilter] = useState('');
    const [propertyNameFilter, setPropertyNameFilter] = useState('');
    const [ratingFilter, setRatingFilter] = useState(0);

    useEffect(() => {
        axios.get('https://api.udiscover.tv/admin/getPropertyReviews/' + password)
            .then(response => {
                setReviews(response.data);
            })
            .catch(error => {
                console.error('Error fetching property reviews:', error);
            });
    }, []);

    const formatDate = (date) => {
        return new Date(date).toLocaleString();
    };

    const filterReviews = () => {
        let filteredReviews = [...reviews];

        if (userNameFilter !== '') {
            filteredReviews = filteredReviews.filter(review => review.users.user_name.toLowerCase().includes(userNameFilter.toLowerCase()));
        }

        if (propertyNameFilter !== '') {
            filteredReviews = filteredReviews.filter(review => review.property.property_name.toLowerCase().includes(propertyNameFilter.toLowerCase()));
        }

        if (ratingFilter !== 0 && ratingFilter !== '') {
            filteredReviews = filteredReviews.filter(review => review.ratings == ratingFilter);
        }

        return filteredReviews;
    };

    const clearFilters = () => {
        setUserNameFilter('');
        setPropertyNameFilter('');
        setRatingFilter(0);
    };

    return (
        <div>
            <h2>All Reviews</h2>
            <div style={{ marginBottom: '16px', display: 'flex', alignItems: 'center' }}>
                <TextField
                    label="User Name"
                    value={userNameFilter}
                    onChange={(e) => setUserNameFilter(e.target.value)}
                    variant="outlined"
                    sx={{ marginRight: '8px' }}
                />
                <TextField
                    label="Property Name"
                    value={propertyNameFilter}
                    onChange={(e) => setPropertyNameFilter(e.target.value)}
                    variant="outlined"
                    sx={{ marginRight: '8px' }}
                />
                <TextField
                    label="Ratings"
                    value={ratingFilter}
                    onChange={(e) => setRatingFilter(e.target.value)}
                    variant="outlined"
                    sx={{ marginRight: '8px' }}
                />
                <Button
                    variant="contained"
                    onClick={clearFilters}
                    sx={{
                        backgroundColor: '#AF9661',
                        color: 'white',
                        '&:hover': {
                            backgroundColor: '#CCCCCC',
                        }
                    }}
                >
                    Clear Filters
                </Button>
            </div>
            <TableContainer component={Paper}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell sx={{ backgroundColor: '#AF9661', color: 'white' }}>User Name</TableCell>
                            <TableCell sx={{ backgroundColor: '#AF9661', color: 'white' }}>Property Name</TableCell>
                            <TableCell sx={{ backgroundColor: '#AF9661', color: 'white' }}>Ratings</TableCell>
                            <TableCell sx={{ backgroundColor: '#AF9661', color: 'white' }}>Review Description</TableCell>
                            <TableCell sx={{ backgroundColor: '#AF9661', color: 'white' }}>Created On</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {filterReviews().map(reviews => (
                            <TableRow key={reviews.review_id}>
                                <TableCell>{reviews.user.user_name}</TableCell>
                                <TableCell>{reviews.property.property_name}</TableCell>
                                <TableCell>{reviews.ratings}</TableCell>
                                <TableCell>{reviews.review_description}</TableCell>
                                <TableCell>{formatDate(reviews.created_on)}</TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </div>
    );
}

export default Reviews;