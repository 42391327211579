import React, { useState } from 'react';
import { Container, AppBar, Toolbar, Typography, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, TextField } from '@mui/material';
import BookingList from './BookingList';
import Users from './Users';
import Properties from './Properties';
import HighlightedProperties from './HighlightedProperties';
import Payments from './Payments';
import Reviews from './Reviews';

function App() {
  const [activeComponent, setActiveComponent] = useState(null);
  const [passwordDialogOpen, setPasswordDialogOpen] = useState(true);
  const [password, setPassword] = useState('');

  const handleMenuClick = (component) => {
    setActiveComponent(component);
  };

  const handlePasswordSubmit = () => {
    setPasswordDialogOpen(false);
  };

  const renderComponent = () => {
    switch (activeComponent) {
      case 'Bookings':
        return <BookingList password={password} />;
      case 'Users':
        return <Users password={password} />;
      case 'Properties':
        return <Properties password={password} />;
      case 'HighlightedProperties':
        return <HighlightedProperties password={password} />;
      case 'Payments':
        return <Payments password={password} />;
      case 'Reviews':
        return <Reviews password={password} />;
      default:
        return null;
    }
  };

  return (
    <div>
      <AppBar position="static" sx={{ backgroundColor: '#AF9661' }}>
        <Toolbar>
          <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
            Discover UK Admin Panel
          </Typography>
          <Button color="inherit" onClick={() => handleMenuClick('Bookings')}>Bookings</Button>
          <Button color="inherit" onClick={() => handleMenuClick('Users')}>Users</Button>
          <Button color="inherit" onClick={() => handleMenuClick('Properties')}>Properties</Button>
          <Button color="inherit" onClick={() => handleMenuClick('Reviews')}>Reviews</Button>
          <Button color="inherit" onClick={() => handleMenuClick('HighlightedProperties')}>Highlighted Properties</Button>
          <Button color="inherit" onClick={() => handleMenuClick('Payments')}>Payments</Button>
        </Toolbar>
      </AppBar>
      <Container sx={{ marginTop: 2 }}>
        {activeComponent && renderComponent()}
      </Container>
      <Dialog open={passwordDialogOpen} onClose={() => setPasswordDialogOpen(false)}>
        <DialogTitle>Please enter the password</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            label="Password"
            type="password"
            fullWidth
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => handlePasswordSubmit()}>OK</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default App;