// Users.js
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, TextField, Button } from '@mui/material';

function Users({ password }) {
    const [users, setUsers] = useState([]);
    const [userNameFilter, setUserNameFilter] = useState('');
    const [addressFilter, setAddressFilter] = useState('');

    useEffect(() => {
        axios.get('https://api.udiscover.tv/admin/getAllUsers/' + password)
            .then(response => {
                setUsers(response.data);
            })
            .catch(error => {
                console.error('Error fetching data:', error);
            });
    }, []);

    const filterUsers = () => {
        let filteredUsers = [...users];

        if (userNameFilter !== '') {
            filteredUsers = filteredUsers.filter(user => user.user_name.toLowerCase().includes(userNameFilter.toLowerCase()));
        }

        if (addressFilter !== '') {
            filteredUsers = filteredUsers.filter(user => {
                if (!user.address) return false;
                return user.address.toLowerCase().includes(addressFilter.toLowerCase());
            });
        }

        return filteredUsers;
    };

    const clearFilters = () => {
        setUserNameFilter('');
        setAddressFilter('');
    };

    return (
        <div>
            <div style={{ display: 'flex', alignItems: 'center' }}>
                <TextField
                    label="Filter by User Name"
                    value={userNameFilter}
                    onChange={(e) => setUserNameFilter(e.target.value)}
                    variant="outlined"
                    sx={{ marginRight: 2 }}
                />
                <TextField
                    label="Filter by Address"
                    value={addressFilter}
                    onChange={(e) => setAddressFilter(e.target.value)}
                    variant="outlined"
                    sx={{ marginRight: 2 }}
                />
                <Button
                    variant="contained"
                    onClick={clearFilters}
                    sx={{
                        backgroundColor: '#AF9661',
                        color: 'white',
                        '&:hover': {
                            backgroundColor: '#CCCCCC',
                        }
                    }}
                >
                    Clear Filters
                </Button>
            </div>
            <br />
            <TableContainer component={Paper}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell sx={{ backgroundColor: '#AF9661', color: 'white' }}>User ID</TableCell>
                            <TableCell sx={{ backgroundColor: '#AF9661', color: 'white' }}>User Name</TableCell>
                            <TableCell sx={{ backgroundColor: '#AF9661', color: 'white' }}>Address</TableCell>
                            <TableCell sx={{ backgroundColor: '#AF9661', color: 'white' }}>Phone No</TableCell>
                            <TableCell sx={{ backgroundColor: '#AF9661', color: 'white' }}>Email ID</TableCell>
                            <TableCell sx={{ backgroundColor: '#AF9661', color: 'white' }}>Created On</TableCell>
                            <TableCell sx={{ backgroundColor: '#AF9661', color: 'white' }}>Updated On</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {filterUsers().map(user => (
                            <TableRow key={user.user_id}>
                                <TableCell>{user.user_id}</TableCell>
                                <TableCell>{user.user_name}</TableCell>
                                <TableCell>{user.address}</TableCell>
                                <TableCell>{user.phone_no}</TableCell>
                                <TableCell>{user.email_id}</TableCell>
                                <TableCell>{new Date(user.created_on).toLocaleString()}</TableCell>
                                <TableCell>{new Date(user.updated_on).toLocaleString()}</TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </div>
    );
}

export default Users;