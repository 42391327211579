import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Button, Dialog, DialogTitle, DialogActions, DialogContent, DialogContentText, TextField, MenuItem, Select } from '@mui/material';

function Bookings({ password }) {
    const [bookings, setBookings] = useState([]);
    const [selectedBookingId, setSelectedBookingId] = useState(null);
    const [updateDialogOpen, setUpdateDialogOpen] = useState(false);
    const [cancellationDialogOpen, setCancellationDialogOpen] = useState(false);
    const [confirmationDialogOpen, setConfirmationDialogOpen] = useState(false);
    const [updateCheckInDate, setUpdateCheckInDate] = useState('');
    const [updateCheckOutDate, setUpdateCheckOutDate] = useState('');
    const [updateGuests, setUpdateGuests] = useState('');
    const [cancellationStatusId, setCancellationStatusId] = useState('');
    const [cancellationResponse, setCancellationResponse] = useState('');
    const [propertyFilter, setPropertyFilter] = useState('');
    const [userFilter, setUserFilter] = useState('');
    const [paymentStatusFilter, setPaymentStatusFilter] = useState('');
    const [checkInDateFilter, setCheckInDateFilter] = useState('');
    const [checkOutDateFilter, setCheckOutDateFilter] = useState('');

    useEffect(() => {
        axios.get('https://api.udiscover.tv/admin/getAllBookings/' + password)
            .then(response => {
                setBookings(response.data);
            })
            .catch(error => {
                console.error('Error fetching bookings:', error);
            });
    }, []);

    const handleUpdateBooking = () => {
        // Call the API to update the booking
        axios.post('https://api.udiscover.tv/admin/updateBooking/' + password, {
            booking_id: selectedBookingId,
            check_in_date: updateCheckInDate,
            check_out_date: updateCheckOutDate,
            guests: updateGuests
        })
            .then(response => {
                console.log('Booking updated successfully');
                setUpdateDialogOpen(false);
                // Refresh bookings after update
                refreshBookings();
            })
            .catch(error => {
                console.error('Error updating booking:', error);
            });
    };

    const handleUpdateDialogOpen = (booking) => {
        setSelectedBookingId(booking.booking_id);
        setUpdateCheckInDate(booking.check_in_date);
        setUpdateCheckOutDate(booking.check_out_date);
        setUpdateGuests(booking.guests);
        setUpdateDialogOpen(true);
    };

    const handleUpdateDialogClose = () => {
        setUpdateDialogOpen(false);
        setSelectedBookingId(null);
        setUpdateCheckInDate('');
        setUpdateCheckOutDate('');
        setUpdateGuests('');
    };

    const handleCancellationDialogOpen = (booking) => {
        setSelectedBookingId(booking.booking_id);
        setCancellationDialogOpen(true);
    };

    const handleCancellation = () => {
        // Call the API to cancel the booking
        axios.post('https://api.udiscover.tv/admin/cancelBooking/' + password, {
            booking_id: selectedBookingId,
            cancellation_status_id: cancellationStatusId,
            cancellation_response: cancellationResponse
        })
            .then(response => {
                console.log('Booking cancelled/denied successfully');
                setCancellationDialogOpen(false);
                // Refresh bookings after cancellation/denial
                refreshBookings();
            })
            .catch(error => {
                console.error('Error cancelling/denying booking:', error);
            });
    };

    const handleCancellationDialogClose = () => {
        setCancellationDialogOpen(false);
        setSelectedBookingId(null);
        setCancellationStatusId('');
        setCancellationResponse('');
    };

    const handleDeleteBooking = (booking) => {
        setSelectedBookingId(booking.booking_id);
        setConfirmationDialogOpen(true);
    };

    const confirmDeleteBooking = () => {
        // Call the API to delete the booking
        axios.post('https://api.udiscover.tv/admin/deleteBooking/' + password, {
            booking_id: selectedBookingId
        })
            .then(response => {
                console.log('Booking deleted successfully');
                setConfirmationDialogOpen(false);
                // Refresh bookings after deletion
                refreshBookings();
            })
            .catch(error => {
                console.error('Error deleting booking:', error);
            });
    };

    const handleConfirmationDialogClose = () => {
        setConfirmationDialogOpen(false);
    };

    const refreshBookings = () => {
        axios.get('https://api.udiscover.tv/admin/getAllBookings/' + password)
            .then(response => {
                setBookings(response.data);
            })
            .catch(error => {
                console.error('Error fetching bookings:', error);
            });
    };

    // Filter function
    const filteredBookings = bookings.filter(booking => {
        const propertyMatch = booking.property.property_name.toLowerCase().includes(propertyFilter.toLowerCase());
        const userMatch = booking.user.user_name.toLowerCase().includes(userFilter.toLowerCase());
        const paymentStatusMatch = getPaymentStatus(booking.payment.payment_status_id).toLowerCase().includes(paymentStatusFilter.toLowerCase());

        // Parse filter dates
        const checkInDateFilterValue = new Date(checkInDateFilter);
        const checkOutDateFilterValue = new Date(checkOutDateFilter);

        // Parse booking dates
        const bookingCheckInDate = new Date(booking.check_in_date);
        const bookingCheckOutDate = new Date(booking.check_out_date);

        // Check date range
        const dateRangeMatch =
            (checkInDateFilter === '' || bookingCheckInDate >= checkInDateFilterValue) &&
            (checkOutDateFilter === '' || bookingCheckOutDate <= checkOutDateFilterValue);

        return propertyMatch && userMatch && paymentStatusMatch && dateRangeMatch;
    });

    return (
        <div>
            <h2 style={{ color: '#AF9661' }}>All Bookings</h2>
            <div style={{ marginBottom: '16px', display: 'flex', alignItems: 'center' }}>
                <TextField
                    label="Property Name"
                    value={propertyFilter}
                    onChange={(e) => setPropertyFilter(e.target.value)}
                    variant="outlined"
                    style={{ marginRight: '16px' }}
                />
                <TextField
                    label="User Name"
                    value={userFilter}
                    onChange={(e) => setUserFilter(e.target.value)}
                    variant="outlined"
                    style={{ marginRight: '16px' }}
                />
                <TextField
                    label="Check In Date"
                    type="date"
                    value={checkInDateFilter}
                    onChange={(e) => setCheckInDateFilter(e.target.value)}
                    variant="outlined"
                    style={{ marginRight: '16px' }}
                />
                <TextField
                    label="Check Out Date"
                    type="date"
                    value={checkOutDateFilter}
                    onChange={(e) => setCheckOutDateFilter(e.target.value)}
                    variant="outlined"
                    style={{ marginRight: '16px' }}
                />
                <Select
                    value={paymentStatusFilter}
                    onChange={(e) => setPaymentStatusFilter(e.target.value)}
                    variant="outlined"
                    style={{ marginRight: '16px', minWidth: '150px' }}
                >
                    <MenuItem value="">All Payment Status</MenuItem>
                    <MenuItem value="Processing">Processing</MenuItem>
                    <MenuItem value="Completed">Completed</MenuItem>
                    <MenuItem value="Declined">Declined</MenuItem>
                    <MenuItem value="Refunded">Refunded</MenuItem>
                </Select>
                <Button onClick={() => {
                    setPropertyFilter('');
                    setUserFilter('');
                    setCheckInDateFilter('');
                    setCheckOutDateFilter('');
                    setPaymentStatusFilter('');
                }} variant="contained" style={{ backgroundColor: '#AF9661', color: 'white' }}>Clear Filter</Button>
            </div>
            <TableContainer component={Paper}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell style={{ backgroundColor: '#AF9661', color: 'white' }}>Booking ID</TableCell>
                            <TableCell style={{ backgroundColor: '#AF9661', color: 'white' }}>User Name</TableCell>
                            <TableCell style={{ backgroundColor: '#AF9661', color: 'white' }}>Property Name</TableCell>
                            <TableCell style={{ backgroundColor: '#AF9661', color: 'white' }}>Payment Status</TableCell>
                            <TableCell style={{ backgroundColor: '#AF9661', color: 'white' }}>Cancellation Status</TableCell>
                            <TableCell style={{ backgroundColor: '#AF9661', color: 'white' }}>Cancellation Reason</TableCell>
                            <TableCell style={{ backgroundColor: '#AF9661', color: 'white' }}>Payment ID</TableCell>
                            <TableCell style={{ backgroundColor: '#AF9661', color: 'white' }}>Check In Date</TableCell>
                            <TableCell style={{ backgroundColor: '#AF9661', color: 'white' }}>Check Out Date</TableCell>
                            <TableCell style={{ backgroundColor: '#AF9661', color: 'white' }}>Guests</TableCell>
                            <TableCell style={{ backgroundColor: '#AF9661', color: 'white' }}>Actions</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {filteredBookings.map(booking => (
                            <TableRow key={booking.booking_id}>
                                <TableCell>{booking.booking_id}</TableCell>
                                <TableCell>{booking.user.user_name}</TableCell>
                                <TableCell>{booking.property.property_name}</TableCell>
                                <TableCell>{getPaymentStatus(booking.payment.payment_status_id)}</TableCell>
                                <TableCell>{getCancellationStatus(booking.cancellation_status)}</TableCell>
                                <TableCell>{booking.cancellation_reason}</TableCell>
                                <TableCell>{booking.payment_id}</TableCell>
                                <TableCell>{booking.check_in_date}</TableCell>
                                <TableCell>{booking.check_out_date}</TableCell>
                                <TableCell>{booking.guests}</TableCell>
                                <TableCell>
                                    <Button variant="contained" onClick={() => handleUpdateDialogOpen(booking)} style={{ backgroundColor: '#AF9661', color: 'white', marginRight: '8px' }}>Edit</Button>
                                    <Button variant="contained" onClick={() => handleCancellationDialogOpen(booking)} style={{ backgroundColor: '#AF9661', color: 'white', marginRight: '8px' }}>Cancellation</Button>
                                    <Button variant="contained" onClick={() => handleDeleteBooking(booking)} style={{ backgroundColor: '#AF9661', color: 'white' }}>Delete</Button>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
            <Dialog open={updateDialogOpen} onClose={handleUpdateDialogClose}>
                <DialogTitle>Edit Booking</DialogTitle>
                <DialogContent>
                    <TextField
                        label="Check In Date"
                        type="date"
                        value={updateCheckInDate}
                        onChange={(e) => setUpdateCheckInDate(e.target.value)}
                        fullWidth
                        variant="outlined"
                        style={{ marginBottom: '16px' }}
                    />
                    <TextField
                        label="Check Out Date"
                        type="date"
                        value={updateCheckOutDate}
                        onChange={(e) => setUpdateCheckOutDate(e.target.value)}
                        fullWidth
                        variant="outlined"
                        style={{ marginBottom: '16px' }}
                    />
                    <TextField
                        label="Guests"
                        type="number"
                        value={updateGuests}
                        onChange={(e) => setUpdateGuests(e.target.value)}
                        fullWidth
                        variant="outlined"
                        style={{ marginBottom: '16px' }}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleUpdateBooking} variant="contained" style={{ backgroundColor: '#AF9661', color: 'white' }}>Update</Button>
                    <Button onClick={handleUpdateDialogClose} variant="contained" style={{ backgroundColor: '#AF9661', color: 'white' }}>Cancel</Button>
                </DialogActions>
            </Dialog>
            <Dialog open={cancellationDialogOpen} onClose={handleCancellationDialogClose}>
                <DialogTitle>Update Cancellation</DialogTitle>
                <DialogContent>
                    <Select
                        value={cancellationStatusId}
                        onChange={(e) => setCancellationStatusId(e.target.value)}
                        fullWidth
                        variant="outlined"
                        style={{ marginBottom: '16px' }}
                    >
                        <MenuItem value={1}>Cancelled</MenuItem>
                        <MenuItem value={3}>Denied</MenuItem>
                    </Select>
                    <TextField
                        label="Cancellation Response"
                        value={cancellationResponse}
                        onChange={(e) => setCancellationResponse(e.target.value)}
                        fullWidth
                        variant="outlined"
                        style={{ marginBottom: '16px' }}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCancellation} variant="contained" style={{ backgroundColor: '#AF9661', color: 'white' }}>Update</Button>
                    <Button onClick={handleCancellationDialogClose} variant="contained" style={{ backgroundColor: '#AF9661', color: 'white' }}>Cancel</Button>
                </DialogActions>
            </Dialog>
            <Dialog open={confirmationDialogOpen} onClose={handleConfirmationDialogClose}>
                <DialogTitle>Confirmation</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Are you sure you want to delete this booking?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={confirmDeleteBooking} variant="contained" style={{ backgroundColor: '#AF9661', color: 'white' }}>Yes</Button>
                    <Button onClick={handleConfirmationDialogClose} variant="contained" style={{ backgroundColor: '#AF9661', color: 'white' }}>No</Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}

const getPaymentStatus = (status) => {
    switch (status) {
        case 1:
            return "Processing";
        case 2:
            return "Completed";
        case 3:
            return "Declined";
        case 4:
            return "Refunded";
        default:
            return "Unknown";
    }
}

const getCancellationStatus = (status) => {
    return status === 1 ? "Cancelled" : "Not Cancelled";
}

export default Bookings;