import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Button, Dialog, DialogTitle, DialogActions, DialogContent, Select, MenuItem } from '@mui/material';

function HighlightedProperties({ password }) {
    const [highlightedProperties, setHighlightedProperties] = useState([]);
    const [allProperties, setAllProperties] = useState([]);
    const [selectedProperty, setSelectedProperty] = useState('');
    const [openDialog, setOpenDialog] = useState(false);
    const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
    const [highlightedPropertyIdToDelete, setHighlightedPropertyIdToDelete] = useState(null);

    useEffect(() => {
        axios.get('https://api.udiscover.tv/admin/getHighlightedProperties/' + password)
            .then(response => {
                setHighlightedProperties(response.data);
            })
            .catch(error => {
                console.error('Error fetching highlighted properties:', error);
            });

        axios.get('https://api.udiscover.tv/admin/getAllProperties/' + password)
            .then(response => {
                setAllProperties(response.data);
            })
            .catch(error => {
                console.error('Error fetching all properties:', error);
            });
    }, []);

    const handleAddHighlight = () => {
        setOpenDialog(true);
    };

    const handleDialogClose = () => {
        setOpenDialog(false);
        setSelectedProperty('');
    };

    const handleAddHighlightProperty = () => {
        // Call the API to add the highlighted property
        axios.post('https://api.udiscover.tv/admin/addHighlightProperty/' + password, {
            property_id: selectedProperty
        })
            .then(response => {
                console.log('Property added as highlight successfully');
                handleDialogClose();
                // Refresh the highlighted properties after addition
                axios.get('https://api.udiscover.tv/admin/getHighlightedProperties/' + password)
                    .then(response => {
                        setHighlightedProperties(response.data);
                    })
                    .catch(error => {
                        console.error('Error fetching highlighted properties:', error);
                    });
            })
            .catch(error => {
                console.error('Error adding property as highlight:', error);
            });
    };

    const handleDeleteHighlight = (highlightedPropertyId) => {
        setHighlightedPropertyIdToDelete(highlightedPropertyId);
        setDeleteDialogOpen(true);
    };

    const handleDeleteDialogClose = () => {
        setDeleteDialogOpen(false);
        setHighlightedPropertyIdToDelete(null);
    };

    const confirmDeleteHighlight = () => {
        // Call the API to delete the highlighted property
        axios.post('https://api.udiscover.tv/admin/deleteHighlightedProperty/' + password, {
            highlight_property_id: highlightedPropertyIdToDelete
        })
            .then(response => {
                console.log('Highlighted property deleted successfully');
                handleDeleteDialogClose();
                // Refresh the highlighted properties after deletion
                axios.get('https://api.udiscover.tv/admin/getHighlightedProperties/' + password)
                    .then(response => {
                        setHighlightedProperties(response.data);
                    })
                    .catch(error => {
                        console.error('Error fetching highlighted properties:', error);
                    });
            })
            .catch(error => {
                console.error('Error deleting highlighted property:', error);
            });
    };

    return (
        <div>
            <h2 style={{ color: '#AF9661' }}>All Highlighted Properties</h2>
            <Button
                variant="contained"
                onClick={handleAddHighlight}
                style={{
                    backgroundColor: '#AF9661',
                    color: 'white',
                    marginBottom: '16px',
                    '&:hover': {
                        backgroundColor: '#CCCCCC',
                    }
                }}
            >
                Add Property as Highlight
            </Button>
            <Dialog open={openDialog} onClose={handleDialogClose}>
                <DialogTitle>Add Property as Highlight</DialogTitle>
                <DialogContent>
                    <Select
                        value={selectedProperty}
                        onChange={(e) => setSelectedProperty(e.target.value)}
                        fullWidth
                        variant="outlined"
                        style={{ marginBottom: '16px' }}
                    >
                        {allProperties.map(property => (
                            <MenuItem key={property.property_id} value={property.property_id}>
                                {property.property_name}
                            </MenuItem>
                        ))}
                    </Select>
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={handleAddHighlightProperty}
                        variant="contained"
                        style={{
                            backgroundColor: '#AF9661',
                            color: 'white',
                            '&:hover': {
                                backgroundColor: '#CCCCCC',
                            }
                        }}
                    >
                        Add
                    </Button>
                </DialogActions>
            </Dialog>
            <TableContainer component={Paper}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell style={{ backgroundColor: '#AF9661', color: 'white' }}>Property Name</TableCell>
                            <TableCell style={{ backgroundColor: '#AF9661', color: 'white' }}>Actions</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {highlightedProperties.map(highlightedProperty => (
                            <TableRow key={highlightedProperty.highlight_property_id}>
                                <TableCell>{highlightedProperty.property.property_name}</TableCell>
                                <TableCell>
                                    <Button
                                        variant="contained"
                                        onClick={() => handleDeleteHighlight(highlightedProperty.highlight_property_id)}
                                        style={{
                                            backgroundColor: '#AF9661',
                                            color: 'white',
                                            '&:hover': {
                                                backgroundColor: '#CCCCCC',
                                            }
                                        }}
                                    >
                                        Delete
                                    </Button>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
            <Dialog open={deleteDialogOpen} onClose={handleDeleteDialogClose}>
                <DialogTitle>Confirm Deletion</DialogTitle>
                <DialogContent>
                    Are you sure you want to remove the highlighted property?
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={confirmDeleteHighlight}
                        variant="contained"
                        style={{
                            backgroundColor: '#AF9661',
                            color: 'white',
                            '&:hover': {
                                backgroundColor: '#CCCCCC',
                            }
                        }}
                    >
                        Yes
                    </Button>
                    <Button
                        onClick={handleDeleteDialogClose}
                        variant="contained"
                        style={{
                            backgroundColor: '#AF9661',
                            color: 'white',
                            '&:hover': {
                                backgroundColor: '#CCCCCC',
                            }
                        }}
                    >
                        No
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}

export default HighlightedProperties;