import React, { useState, useEffect } from 'react';
import axios from 'axios';
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    TextField,
    Button,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Select,
    MenuItem,
    Checkbox,
    FormControlLabel,
} from '@mui/material';

function Properties({ password }) {
    const [properties, setProperties] = useState([]);
    const [nameFilter, setNameFilter] = useState('');
    const [hostNameFilter, setHostNameFilter] = useState('');
    const [amenitiesFilter, setAmenitiesFilter] = useState([]);
    const [priceFilter, setPriceFilter] = useState('');
    const [locationFilter, setLocationFilter] = useState('');
    const [reviewsFilter, setReviewsFilter] = useState('');
    const [guestsFilter, setGuestsFilter] = useState('');
    const [openDialog, setOpenDialog] = useState(false);
    const [selectedProperty, setSelectedProperty] = useState(null);
    const [editedPropertyName, setEditedPropertyName] = useState('');
    const [editedHostName, setEditedHostName] = useState('');
    const [editedDescription, setEditedDescription] = useState('');
    const [editedAmenities, setEditedAmenities] = useState([]);
    const [editedPrice, setEditedPrice] = useState('');
    const [editedLocation, setEditedLocation] = useState('');
    const [editedGuests, setEditedGuests] = useState('');
    const [deleteConfirmationOpen, setDeleteConfirmationOpen] = useState(false);
    const [propertyIdToDelete, setPropertyIdToDelete] = useState(null);
    const [addPropertyOpen, setAddPropertyOpen] = useState(false);

    const [propertyTypeId, setPropertyTypeId] = useState('');
    const [addPropertyName, setAddPropertyName] = useState('');
    const [addHostName, setAddHostName] = useState('');
    const [addDescription, setAddDescription] = useState('');
    const [addAmenities, setAddAmenities] = useState([]);
    const [addPrice, setAddPrice] = useState('');
    const [addLocation, setAddLocation] = useState('');
    const [addGuests, setAddGuests] = useState('');
    const [addMinDays, setAddMinDays] = useState('');
    const [addMaxDays, setAddMaxDays] = useState('');
    const [addRules, setAddRules] = useState('');
    const [addCheckInTime, setAddCheckInTime] = useState('');
    const [addCheckOutTime, setAddCheckOutTime] = useState('');

    const [selectedFiles, setSelectedFiles] = useState([]);
    const [imagesDialog, setImagesDialog] = useState(false);

    useEffect(() => {
        axios
            .get('https://api.udiscover.tv/admin/getAllProperties/' + password)
            .then((response) => {
                setProperties(response.data);
            })
            .catch((error) => {
                console.error('Error fetching property data:', error);
            });
    }, []);

    const amenitiesOptions = [
        { value: 'wifi', label: 'WiFi' },
        { value: 'parking', label: 'Parking' },
        { value: 'tv', label: 'TV' },
        { value: 'radio', label: 'Radio' },
        { value: 'pets', label: 'Pets' },
        { value: 'entrance', label: 'Entrance' },
    ];

    const formatPrice = (price) => {
        return `£${price}`;
    };

    const formatDate = (date) => {
        return new Date(date).toLocaleString();
    };

    const filterProperties = () => {
        let filteredProperties = [...properties];

        if (nameFilter !== '') {
            filteredProperties = filteredProperties.filter((property) =>
                property.property_name.toLowerCase().includes(nameFilter.toLowerCase())
            );
        }

        if (hostNameFilter !== '') {
            filteredProperties = filteredProperties.filter((property) =>
                property.host_name.toLowerCase().includes(hostNameFilter.toLowerCase())
            );
        }

        if (amenitiesFilter.length > 0) {
            filteredProperties = filteredProperties.filter((property) => {
                const propertyAmenities = property.amenities.split(',').map((item) => item.trim());
                return amenitiesFilter.every((amenity) => propertyAmenities.includes(amenity));
            });
        }

        if (priceFilter !== '') {
            filteredProperties = filteredProperties.filter((property) => property.property_price >= parseInt(priceFilter));
        }

        if (locationFilter !== '') {
            filteredProperties = filteredProperties.filter((property) =>
                property.location_description.toLowerCase().includes(locationFilter.toLowerCase())
            );
        }

        if (reviewsFilter !== '') {
            filteredProperties = filteredProperties.filter(
                (property) => property.average_reviews >= parseFloat(reviewsFilter)
            );
        }

        if (guestsFilter !== '') {
            filteredProperties = filteredProperties.filter((property) => property.guests >= parseInt(guestsFilter));
        }

        return filteredProperties;
    };

    const clearFilters = () => {
        setNameFilter('');
        setHostNameFilter('');
        setAmenitiesFilter([]);
        setPriceFilter('');
        setLocationFilter('');
        setReviewsFilter('');
        setGuestsFilter('');
    };

    const handleEdit = (property) => {
        setSelectedProperty(property);
        setEditedPropertyName(property.property_name);
        setEditedHostName(property.host_name);
        setEditedDescription(property.property_description);
        setEditedAmenities(property.amenities.split(',').map((item) => item.trim()));
        setEditedPrice(property.property_price);
        setEditedLocation(property.location_description);
        setEditedGuests(property.guests);
        setOpenDialog(true);
    };

    const handleDialogClose = () => {
        setOpenDialog(false);
        setSelectedProperty(null);
    };

    const handleOpenImagesDialog = () => {
        setImagesDialog(true);
    };

    const handleCloseImagesDialog = () => {
        setImagesDialog(false);
    };

    const handleFileChange = (e) => {
        setSelectedFiles(e.target.files);
    };

    const handlePropertySelect = (e) => {
        setSelectedProperty(e);
    };

    const handleUpload = () => {
        const formData = new FormData();
        formData.append('property_id', selectedProperty.property_id);
        Array.from(selectedFiles).forEach((file) => {
            formData.append('photos', file);
        });
        axios
            .post('https://api.udiscover.tv/admin/updatePropertyPhotos/' + password, formData)
            .then((response) => {
                console.log('Images uploaded successfully');
                // Close the dialog after successful upload
                handleCloseImagesDialog();
            })
            .catch((error) => {
                console.error('Error uploading images:', error);
            });
    };

    const handleUpdateProperty = () => {
        const updatedAmenities = editedAmenities.join(', ');

        axios
            .post('https://api.udiscover.tv/admin/updateProperty/' + password, {
                property_id: selectedProperty.property_id,
                property_name: editedPropertyName,
                host_name: editedHostName,
                property_description: editedDescription,
                amenities: updatedAmenities,
                property_price: editedPrice,
                location_description: editedLocation,
                guests: editedGuests,
            })
            .then((response) => {
                console.log('Property updated successfully');
                handleDialogClose();
            })
            .catch((error) => {
                console.error('Error updating property:', error);
            });
    };

    const handleAddProperty = () => {
        const updatedAmenities = addAmenities.join(', ');

        axios
            .post('https://api.udiscover.tv/admin/addProperty/' + password, {
                property_type_id: propertyTypeId,
                property_name: addPropertyName,
                host_name: addHostName,
                property_description: addDescription,
                amenities: updatedAmenities,
                property_price: addPrice,
                location_description: addLocation,
                guests: addGuests,
                min_days: addMinDays,
                max_days: addMaxDays,
                rules: addRules,
                check_in_time: addCheckInTime,
                check_out_time: addCheckOutTime,
            })
            .then((response) => {
                console.log('Property added successfully');
                handleAddPropertyClose();
            })
            .catch((error) => {
                console.error('Error adding property:', error);
            });
    };

    const handleDeleteConfirmationOpen = (propertyId) => {
        setPropertyIdToDelete(propertyId);
        setDeleteConfirmationOpen(true);
    };

    const handleDeleteConfirmationClose = () => {
        setPropertyIdToDelete(null);
        setDeleteConfirmationOpen(false);
    };

    const handleAddPropertyOpen = () => {
        setAddPropertyOpen(true);
    };

    const handleAddPropertyClose = () => {
        setAddPropertyOpen(false);
    };

    const handleDeleteProperty = () => {
        axios
            .post('https://api.udiscover.tv/admin/deleteProperty/' + password, {
                property_id: propertyIdToDelete,
            })
            .then((response) => {
                console.log('Property deleted successfully');
                handleDeleteConfirmationClose();
                // Refresh the property data after deletion
                axios
                    .get('https://api.udiscover.tv/admin/getAllProperties/' + password)
                    .then((response) => {
                        setProperties(response.data);
                    })
                    .catch((error) => {
                        console.error('Error fetching property data:', error);
                    });
            })
            .catch((error) => {
                console.error('Error deleting property:', error);
            });
    };

    const formatPropertyType = (propertyTypeId) => {
        switch (propertyTypeId) {
            case 1:
                return "Residential";
            case 2:
                return "Commercial";
        }
    };

    return (
        <div>
            <h2>All Properties</h2>
            <Button
                variant="contained"
                onClick={() => handleAddPropertyOpen()}
                sx={{
                    backgroundColor: '#AF9661',
                    color: 'white',
                    '&:hover': {
                        backgroundColor: '#CCCCCC',
                    },
                    marginLeft: '8px',
                }}
            >
                Add Property
            </Button>
            <Dialog open={addPropertyOpen} onClose={handleAddPropertyClose}>
                <DialogTitle>Add Property</DialogTitle>
                <DialogContent>
                    <Select
                        value={propertyTypeId}
                        onChange={(e) => setPropertyTypeId(e.target.value)}
                        fullWidth
                        variant="outlined"
                        type="number"
                        sx={{ marginBottom: '16px' }}
                    >
                        <MenuItem value={1}>Residential</MenuItem>
                        <MenuItem value={2}>Commercial</MenuItem>
                    </Select>
                    <TextField
                        label="Name"
                        value={addPropertyName}
                        onChange={(e) => setAddPropertyName(e.target.value)}
                        fullWidth
                        variant="outlined"
                        sx={{ marginBottom: '16px' }}
                    />
                    <TextField
                        label="Host Name"
                        value={addHostName}
                        onChange={(e) => setAddHostName(e.target.value)}
                        fullWidth
                        variant="outlined"
                        sx={{ marginBottom: '16px' }}
                    />
                    <TextField
                        label="Description"
                        value={addDescription}
                        onChange={(e) => setAddDescription(e.target.value)}
                        fullWidth
                        variant="outlined"
                        multiline
                        rows={4}
                        sx={{ marginBottom: '16px' }}
                    />
                    <FormControlLabel
                        control={
                            <Select
                                label="Amenities"
                                value={addAmenities}
                                onChange={(e) => setAddAmenities(e.target.value)}
                                multiple
                                variant="outlined"
                                sx={{ marginBottom: '16px' }}
                                renderValue={(selected) => selected.join(', ')}
                            >
                                {amenitiesOptions.map((option) => (
                                    <MenuItem key={option.value} value={option.value}>
                                        <Checkbox checked={addAmenities.indexOf(option.value) > -1} />
                                        {option.label}
                                    </MenuItem>
                                ))}
                            </Select>
                        }
                        label="Amenities"
                        sx={{ marginBottom: '16px' }}
                    />
                    <TextField
                        label="Price"
                        value={addPrice}
                        onChange={(e) => setAddPrice(e.target.value)}
                        fullWidth
                        variant="outlined"
                        type="number"
                        sx={{ marginBottom: '16px' }}
                    />
                    <TextField
                        label="Location"
                        value={addLocation}
                        onChange={(e) => setAddLocation(e.target.value)}
                        fullWidth
                        variant="outlined"
                        sx={{ marginBottom: '16px' }}
                    />
                    <TextField
                        label="Guests"
                        value={addGuests}
                        onChange={(e) => setAddGuests(e.target.value)}
                        fullWidth
                        variant="outlined"
                        type="number"
                        sx={{ marginBottom: '16px' }}
                    />
                    <TextField
                        label="Min Days"
                        value={addMinDays}
                        onChange={(e) => setAddMinDays(e.target.value)}
                        fullWidth
                        variant="outlined"
                        type="number"
                        sx={{ marginBottom: '16px' }}
                    />
                    <TextField
                        label="Max Days"
                        value={addMaxDays}
                        onChange={(e) => setAddMaxDays(e.target.value)}
                        fullWidth
                        variant="outlined"
                        type="number"
                        sx={{ marginBottom: '16px' }}
                    />
                    <TextField
                        label="Rules"
                        value={addRules}
                        onChange={(e) => setAddRules(e.target.value)}
                        fullWidth
                        variant="outlined"
                        sx={{ marginBottom: '16px' }}
                    />
                    <TextField
                        label="Check In Time"
                        value={addCheckInTime}
                        onChange={(e) => setAddCheckInTime(e.target.value)}
                        fullWidth
                        variant="outlined"
                        sx={{ marginBottom: '16px' }}
                    />
                    <TextField
                        label="Check Out Time"
                        value={addCheckOutTime}
                        onChange={(e) => setAddCheckOutTime(e.target.value)}
                        fullWidth
                        variant="outlined"
                        sx={{ marginBottom: '16px' }}
                    />
                </DialogContent>

                <DialogActions>
                    <Button
                        onClick={handleAddProperty}
                        variant="contained"
                        sx={{
                            backgroundColor: '#AF9661',
                            color: 'white',
                            '&:hover': {
                                backgroundColor: '#CCCCCC',
                            },
                        }}
                    >
                        Add
                    </Button>
                </DialogActions>
            </Dialog>
            <Button
                variant="contained"
                onClick={() => handleOpenImagesDialog()}
                sx={{
                    backgroundColor: '#AF9661',
                    color: 'white',
                    '&:hover': {
                        backgroundColor: '#CCCCCC',
                    },
                    marginLeft: '8px',
                }}
            >
                Add Images
            </Button>
            {/* Dialog for selecting property and images */}
            <Dialog open={imagesDialog} onClose={handleCloseImagesDialog}>
                <DialogTitle>Select Property and Upload Images</DialogTitle>
                <DialogContent>
                    <Select onChange={(e) => handlePropertySelect(e.target.value)}>
                        {properties.map((property) => (
                            <MenuItem key={property.property_id} value={property}>
                                {property.property_name}
                            </MenuItem>
                        ))}
                    </Select>
                    <input type="file" multiple onChange={handleFileChange} />
                </DialogContent>
                {/* Actions in the dialog */}
                <DialogActions>
                    {/* Button to upload images */}
                    <Button onClick={handleUpload} variant="contained">
                        Upload
                    </Button>
                </DialogActions>
            </Dialog>

            <br></br>
            <br></br>
            <div style={{ marginBottom: '16px', display: 'flex', alignItems: 'center' }}>
                <TextField
                    label="Name"
                    value={nameFilter}
                    onChange={(e) => setNameFilter(e.target.value)}
                    variant="outlined"
                    sx={{ marginRight: '8px' }}
                />
                <TextField
                    label="Host Name"
                    value={hostNameFilter}
                    onChange={(e) => setHostNameFilter(e.target.value)}
                    variant="outlined"
                    sx={{ marginRight: '8px' }}
                />
                <Select
                    label="Amenities"
                    value={amenitiesFilter}
                    onChange={(e) => setAmenitiesFilter(e.target.value)}
                    multiple
                    variant="outlined"
                    displayEmpty
                    renderValue={(selected) => selected.join(', ')}
                    sx={{ minWidth: '120px', marginRight: '8px' }}
                >
                    {amenitiesOptions.map((option) => (
                        <MenuItem key={option.value} value={option.value}>
                            <Checkbox checked={amenitiesFilter.indexOf(option.value) > -1} />
                            {option.label}
                        </MenuItem>
                    ))}
                </Select>
                <TextField
                    label="Price"
                    value={priceFilter}
                    onChange={(e) => setPriceFilter(e.target.value)}
                    variant="outlined"
                    type="number"
                    sx={{ marginRight: '8px' }}
                />
                <TextField
                    label="Location"
                    value={locationFilter}
                    onChange={(e) => setLocationFilter(e.target.value)}
                    variant="outlined"
                    sx={{ marginRight: '8px' }}
                />
                <TextField
                    label="Reviews"
                    value={reviewsFilter}
                    onChange={(e) => setReviewsFilter(e.target.value)}
                    variant="outlined"
                    type="number"
                    sx={{ marginRight: '8px' }}
                />
                <TextField
                    label="Guests"
                    value={guestsFilter}
                    onChange={(e) => setGuestsFilter(e.target.value)}
                    variant="outlined"
                    type="number"
                    sx={{ marginRight: '8px' }}
                />
                <Button
                    variant="contained"
                    onClick={clearFilters}
                    sx={{
                        backgroundColor: '#AF9661',
                        color: 'white',
                        '&:hover': {
                            backgroundColor: '#CCCCCC',
                        },
                    }}
                >
                    Clear Filters
                </Button>
            </div>
            <TableContainer component={Paper}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell sx={{ backgroundColor: '#AF9661', color: 'white' }}>Name</TableCell>
                            <TableCell sx={{ backgroundColor: '#AF9661', color: 'white' }}>Property Type</TableCell>
                            <TableCell sx={{ backgroundColor: '#AF9661', color: 'white' }}>Host Name</TableCell>
                            <TableCell sx={{ backgroundColor: '#AF9661', color: 'white' }}>Amenities</TableCell>
                            <TableCell sx={{ backgroundColor: '#AF9661', color: 'white' }}>Price</TableCell>
                            <TableCell sx={{ backgroundColor: '#AF9661', color: 'white' }}>Location</TableCell>
                            <TableCell sx={{ backgroundColor: '#AF9661', color: 'white' }}>Reviews</TableCell>
                            <TableCell sx={{ backgroundColor: '#AF9661', color: 'white' }}>Guests</TableCell>
                            <TableCell sx={{ backgroundColor: '#AF9661', color: 'white' }}>Actions</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {filterProperties().map((property) => (
                            <TableRow key={property.property_id}>
                                <TableCell>{property.property_name}</TableCell>
                                <TableCell>{formatPropertyType(property.property_type_id)}</TableCell>
                                <TableCell>{property.host_name}</TableCell>
                                <TableCell>{property.amenities}</TableCell>
                                <TableCell>{formatPrice(property.property_price)}</TableCell>
                                <TableCell>{property.location_description}</TableCell>
                                <TableCell>{property.average_reviews}</TableCell>
                                <TableCell>{property.guests}</TableCell>
                                <TableCell>
                                    <Button
                                        variant="contained"
                                        onClick={() => handleEdit(property)}
                                        sx={{
                                            backgroundColor: '#AF9661',
                                            color: 'white',
                                            '&:hover': {
                                                backgroundColor: '#CCCCCC',
                                            },
                                        }}
                                    >
                                        Edit
                                    </Button>
                                    <Button
                                        variant="contained"
                                        onClick={() => handleDeleteConfirmationOpen(property.property_id)}
                                        sx={{
                                            backgroundColor: '#AF9661',
                                            color: 'white',
                                            '&:hover': {
                                                backgroundColor: '#CCCCCC',
                                            },
                                            marginLeft: '8px',
                                        }}
                                    >
                                        Delete
                                    </Button>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
            <Dialog open={openDialog} onClose={handleDialogClose}>
                <DialogTitle>Edit Property</DialogTitle>
                <DialogContent>
                    <TextField
                        label="Name"
                        value={editedPropertyName}
                        onChange={(e) => setEditedPropertyName(e.target.value)}
                        fullWidth
                        variant="outlined"
                        sx={{ marginBottom: '16px' }}
                    />
                    <TextField
                        label="Host Name"
                        value={editedHostName}
                        onChange={(e) => setEditedHostName(e.target.value)}
                        fullWidth
                        variant="outlined"
                        sx={{ marginBottom: '16px' }}
                    />
                    <TextField
                        label="Description"
                        value={editedDescription}
                        onChange={(e) => setEditedDescription(e.target.value)}
                        fullWidth
                        variant="outlined"
                        multiline
                        rows={4}
                        sx={{ marginBottom: '16px' }}
                    />
                    <FormControlLabel
                        control={
                            <Select
                                label="Amenities"
                                value={editedAmenities}
                                onChange={(e) => setEditedAmenities(e.target.value)}
                                multiple
                                variant="outlined"
                                sx={{ marginBottom: '16px' }}
                                renderValue={(selected) => selected.join(', ')}
                            >
                                {amenitiesOptions.map((option) => (
                                    <MenuItem key={option.value} value={option.value}>
                                        <Checkbox checked={editedAmenities.indexOf(option.value) > -1} />
                                        {option.label}
                                    </MenuItem>
                                ))}
                            </Select>
                        }
                        label="Amenities"
                        sx={{ marginBottom: '16px' }}
                    />
                    <TextField
                        label="Price"
                        value={editedPrice}
                        onChange={(e) => setEditedPrice(e.target.value)}
                        fullWidth
                        variant="outlined"
                        type="number"
                        sx={{ marginBottom: '16px' }}
                    />
                    <TextField
                        label="Location"
                        value={editedLocation}
                        onChange={(e) => setEditedLocation(e.target.value)}
                        fullWidth
                        variant="outlined"
                        sx={{ marginBottom: '16px' }}
                    />
                    <TextField
                        label="Guests"
                        value={editedGuests}
                        onChange={(e) => setEditedGuests(e.target.value)}
                        fullWidth
                        variant="outlined"
                        type="number"
                        sx={{ marginBottom: '16px' }}
                    />
                </DialogContent>

                <DialogActions>
                    <Button
                        onClick={handleUpdateProperty}
                        variant="contained"
                        sx={{
                            backgroundColor: '#AF9661',
                            color: 'white',
                            '&:hover': {
                                backgroundColor: '#CCCCCC',
                            },
                        }}
                    >
                        Update
                    </Button>
                </DialogActions>
            </Dialog>
            <Dialog open={deleteConfirmationOpen} onClose={handleDeleteConfirmationClose}>
                <DialogTitle>Delete Property</DialogTitle>
                <DialogContent>Are you sure you want to delete this property?</DialogContent>
                <DialogActions>
                    <Button
                        onClick={handleDeleteConfirmationClose}
                        variant="outlined"
                        sx={{ color: 'red', borderColor: 'red' }}
                    >
                        Cancel
                    </Button>
                    <Button onClick={handleDeleteProperty} variant="contained" sx={{ backgroundColor: 'red', color: 'white' }}>
                        Delete
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}

export default Properties;