import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, TextField, Button, Dialog, DialogTitle, DialogContent, DialogActions, Select, MenuItem } from '@mui/material';

function Payments({ password }) {
    const [payments, setPayments] = useState([]);
    const [userNameFilter, setUserNameFilter] = useState('');
    const [propertyNameFilter, setPropertyNameFilter] = useState('');
    const [paymentStatusFilter, setPaymentStatusFilter] = useState('');
    const [paymentTypeFilter, setPaymentTypeFilter] = useState('');
    const [editPaymentId, setEditPaymentId] = useState(null);
    const [newPaymentStatus, setNewPaymentStatus] = useState('');
    const [openDialog, setOpenDialog] = useState(false);

    useEffect(() => {
        axios.get('https://api.udiscover.tv/admin/getAllPayments/' + password)
            .then(response => {
                setPayments(response.data);
            })
            .catch(error => {
                console.error('Error fetching payment data:', error);
            });
    }, []);

    const formatAmount = (amount) => {
        return `£${amount}`;
    };

    const formatPaymentStatus = (statusId) => {
        switch (statusId) {
            case 1:
                return "Processing";
            case 2:
                return "Completed";
            case 3:
                return "Declined";
            case 4:
                return "Refunded";
            default:
                return "Unknown";
        }
    };

    const formatPaymentType = (typeId) => {
        switch (typeId) {
            case 1:
                return "Card";
            case 2:
                return "Paypal";
            default:
                return "Unknown";
        }
    };

    const formatDate = (date) => {
        return new Date(date).toLocaleString();
    };

    const filterPayments = () => {
        let filteredPayments = [...payments];

        if (userNameFilter !== '') {
            filteredPayments = filteredPayments.filter(payment => payment.userData.user_name.toLowerCase().includes(userNameFilter.toLowerCase()));
        }

        if (propertyNameFilter !== '') {
            filteredPayments = filteredPayments.filter(payment => payment.property.property_name.toLowerCase().includes(propertyNameFilter.toLowerCase()));
        }

        if (paymentStatusFilter !== '') {
            filteredPayments = filteredPayments.filter(payment => formatPaymentStatus(payment.payment_status_id).toLowerCase().includes(paymentStatusFilter.toLowerCase()));
        }

        if (paymentTypeFilter !== '') {
            filteredPayments = filteredPayments.filter(payment => formatPaymentType(payment.payment_type_id).toLowerCase().includes(paymentTypeFilter.toLowerCase()));
        }

        return filteredPayments;
    };

    const clearFilters = () => {
        setUserNameFilter('');
        setPropertyNameFilter('');
        setPaymentStatusFilter('');
        setPaymentTypeFilter('');
    };

    const handleEdit = (paymentId) => {
        setEditPaymentId(paymentId);
        setOpenDialog(true);
    };

    const handleDialogClose = () => {
        setOpenDialog(false);
        setEditPaymentId(null);
        setNewPaymentStatus('');
    };

    const handleUpdatePayment = () => {
        // Call the API to update the payment status
        axios.post('https://api.udiscover.tv/admin/updatePayments/' + password, {
            payment_id: editPaymentId,
            payment_status_id: newPaymentStatus
        })
            .then(response => {
                console.log('Payment status updated successfully');
                handleDialogClose();
                // You may want to refresh the payments data after update
            })
            .catch(error => {
                console.error('Error updating payment status:', error);
            });
    };

    return (
        <div>
            <h2>All Payments</h2>
            <div style={{ marginBottom: '16px', display: 'flex', alignItems: 'center' }}>
                <TextField
                    label="User Name"
                    value={userNameFilter}
                    onChange={(e) => setUserNameFilter(e.target.value)}
                    variant="outlined"
                    sx={{ marginRight: '8px' }}
                />
                <TextField
                    label="Property Name"
                    value={propertyNameFilter}
                    onChange={(e) => setPropertyNameFilter(e.target.value)}
                    variant="outlined"
                    sx={{ marginRight: '8px' }}
                />
                <TextField
                    label="Payment Status"
                    value={paymentStatusFilter}
                    onChange={(e) => setPaymentStatusFilter(e.target.value)}
                    variant="outlined"
                    sx={{ marginRight: '8px' }}
                />
                <TextField
                    label="Payment Type"
                    value={paymentTypeFilter}
                    onChange={(e) => setPaymentTypeFilter(e.target.value)}
                    variant="outlined"
                    sx={{ marginRight: '8px' }}
                />
                <Button
                    variant="contained"
                    onClick={clearFilters}
                    sx={{
                        backgroundColor: '#AF9661',
                        color: 'white',
                        '&:hover': {
                            backgroundColor: '#CCCCCC',
                        }
                    }}
                >
                    Clear Filters
                </Button>
            </div>
            <TableContainer component={Paper}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell sx={{ backgroundColor: '#AF9661', color: 'white' }}>Payment ID</TableCell>
                            <TableCell sx={{ backgroundColor: '#AF9661', color: 'white' }}>User Name</TableCell>
                            <TableCell sx={{ backgroundColor: '#AF9661', color: 'white' }}>Property Name</TableCell>
                            <TableCell sx={{ backgroundColor: '#AF9661', color: 'white' }}>Amount</TableCell>
                            <TableCell sx={{ backgroundColor: '#AF9661', color: 'white' }}>Payment Status</TableCell>
                            <TableCell sx={{ backgroundColor: '#AF9661', color: 'white' }}>Payment Type</TableCell>
                            <TableCell sx={{ backgroundColor: '#AF9661', color: 'white' }}>Created On</TableCell>
                            <TableCell sx={{ backgroundColor: '#AF9661', color: 'white' }}>Updated On</TableCell>
                            <TableCell sx={{ backgroundColor: '#AF9661', color: 'white' }}>Actions</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {filterPayments().map(payment => (
                            <TableRow key={payment.payment_id}>
                                <TableCell>{payment.payment_id}</TableCell>
                                <TableCell>{payment.userData.user_name}</TableCell>
                                <TableCell>{payment.property.property_name}</TableCell>
                                <TableCell>{formatAmount(payment.amount)}</TableCell>
                                <TableCell>{formatPaymentStatus(payment.payment_status_id)}</TableCell>
                                <TableCell>{formatPaymentType(payment.payment_type_id)}</TableCell>
                                <TableCell>{formatDate(payment.created_on)}</TableCell>
                                <TableCell>{formatDate(payment.updated_on)}</TableCell>
                                <TableCell>
                                    <Button
                                        variant="contained"
                                        onClick={() => handleEdit(payment.payment_id)}
                                        sx={{
                                            backgroundColor: '#AF9661',
                                            color: 'white',
                                            '&:hover': {
                                                backgroundColor: '#CCCCCC',
                                            }
                                        }}
                                    >
                                        Edit
                                    </Button>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
            <Dialog open={openDialog} onClose={handleDialogClose}>
                <DialogTitle>Edit Payment Status</DialogTitle>
                <DialogContent>
                    <Select
                        value={newPaymentStatus}
                        onChange={(e) => setNewPaymentStatus(e.target.value)}
                        fullWidth
                        variant="outlined"
                        sx={{ marginBottom: '16px' }}
                    >
                        <MenuItem value={1}>Processing</MenuItem>
                        <MenuItem value={2}>Completed</MenuItem>
                        <MenuItem value={3}>Declined</MenuItem>
                        <MenuItem value={4}>Refunded</MenuItem>
                    </Select>
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={handleUpdatePayment}
                        variant="contained"
                        sx={{
                            backgroundColor: '#AF9661',
                            color: 'white',
                            '&:hover': {
                                backgroundColor: '#CCCCCC',
                            }
                        }}
                    >
                        Update
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}

export default Payments;